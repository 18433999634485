import { default as _404jbEN0VlOpWMeta } from "/Users/rice/xtable-pos-v3/pages/404.vue?macro=true";
import { default as expiredY98HGdYJWnMeta } from "/Users/rice/xtable-pos-v3/pages/expired.vue?macro=true";
import { default as forgot_45passwordnf3m2vyfLNMeta } from "/Users/rice/xtable-pos-v3/pages/forgot-password.vue?macro=true";
import { default as indexe9ScMyZqTnMeta } from "/Users/rice/xtable-pos-v3/pages/index.vue?macro=true";
import { default as loginlfIMpUCoYFMeta } from "/Users/rice/xtable-pos-v3/pages/login.vue?macro=true";
import { default as notallowedbLnoPAwsF4Meta } from "/Users/rice/xtable-pos-v3/pages/notallowed.vue?macro=true";
import { default as runmode46stoh9igdMeta } from "/Users/rice/xtable-pos-v3/pages/runmode.vue?macro=true";
import { default as signupx92gHNJ9ImMeta } from "/Users/rice/xtable-pos-v3/pages/signup.vue?macro=true";
import { default as unauthorizedajQwWkGQ8HMeta } from "/Users/rice/xtable-pos-v3/pages/unauthorized.vue?macro=true";
import { default as indexTViQ4uuFo2Meta } from "/Users/rice/xtable-pos-v3/modules/dashboard/pages/dashboard/index.vue?macro=true";
import { default as receipts4VxNeErPREMeta } from "/Users/rice/xtable-pos-v3/modules/dashboard/pages/dashboard/receipts.vue?macro=true";
import { default as livehxmfIV9xU5Meta } from "/Users/rice/xtable-pos-v3/modules/dashboard/pages/dashboard/live.vue?macro=true";
import { default as productszM0XdXj76VMeta } from "/Users/rice/xtable-pos-v3/modules/dashboard/pages/dashboard/products.vue?macro=true";
import { default as voided_45itemsQPgkBKKp3TMeta } from "/Users/rice/xtable-pos-v3/modules/dashboard/pages/dashboard/voided-items.vue?macro=true";
import { default as closeHXj1twe5zbMeta } from "/Users/rice/xtable-pos-v3/modules/dashboard/pages/dashboard/close.vue?macro=true";
import { default as dashboard0k9MxbpLWZMeta } from "/Users/rice/xtable-pos-v3/modules/dashboard/pages/dashboard.vue?macro=true";
import { default as logsmRI4lYznnaMeta } from "/Users/rice/xtable-pos-v3/modules/history/pages/logs.vue?macro=true";
import { default as listpf9LorVIzJMeta } from "/Users/rice/xtable-pos-v3/modules/inventory/pages/inventory/list.vue?macro=true";
import { default as insertKq2KjRtWi1Meta } from "/Users/rice/xtable-pos-v3/modules/inventory/pages/inventory/insert.vue?macro=true";
import { default as add7XI8aK5OtRMeta } from "/Users/rice/xtable-pos-v3/modules/inventory/pages/inventory/add.vue?macro=true";
import { default as editvGE8jqpEzjMeta } from "/Users/rice/xtable-pos-v3/modules/inventory/pages/inventory/edit.vue?macro=true";
import { default as detailiC4trxDgEYMeta } from "/Users/rice/xtable-pos-v3/modules/inventory/pages/inventory/detail.vue?macro=true";
import { default as reportJOXJ1VENtKMeta } from "/Users/rice/xtable-pos-v3/modules/inventory/pages/inventory/report.vue?macro=true";
import { default as inventorynUcJqKwD4KMeta } from "/Users/rice/xtable-pos-v3/modules/inventory/pages/inventory.vue?macro=true";
import { default as index4ZoJ4nVQJSMeta } from "/Users/rice/xtable-pos-v3/modules/pos/pages/index.vue?macro=true";
import { default as cartokBaYDZQRdMeta } from "/Users/rice/xtable-pos-v3/modules/pos/pages/cart.vue?macro=true";
import { default as indexHMSLLnNF0EMeta } from "/Users/rice/xtable-pos-v3/modules/product/pages/menu/index.vue?macro=true";
import { default as groupsIFdcWRugD1Meta } from "/Users/rice/xtable-pos-v3/modules/product/pages/menu/groups.vue?macro=true";
import { default as itemsIhApGYbW2aMeta } from "/Users/rice/xtable-pos-v3/modules/product/pages/menu/items.vue?macro=true";
import { default as addonwLomnObQ0RMeta } from "/Users/rice/xtable-pos-v3/modules/product/pages/menu/addon.vue?macro=true";
import { default as qrcodeaKREs0TtbGMeta } from "/Users/rice/xtable-pos-v3/modules/product/pages/menu/qrcode.vue?macro=true";
import { default as tagRDOcDTjqLcMeta } from "/Users/rice/xtable-pos-v3/modules/product/pages/menu/tag.vue?macro=true";
import { default as categories80zCodwa4gMeta } from "/Users/rice/xtable-pos-v3/modules/product/pages/menu/categories.vue?macro=true";
import { default as channelBitE4NfFfCMeta } from "/Users/rice/xtable-pos-v3/modules/product/pages/menu/channel.vue?macro=true";
import { default as printerLBrDHAWSmGMeta } from "/Users/rice/xtable-pos-v3/modules/product/pages/menu/printer.vue?macro=true";
import { default as items_batchInc1O5SMVKMeta } from "/Users/rice/xtable-pos-v3/modules/product/pages/menu/items_batch.vue?macro=true";
import { default as items_batch2ObmptZFzRzMeta } from "/Users/rice/xtable-pos-v3/modules/product/pages/menu/items_batch2.vue?macro=true";
import { default as openMenu8fXnp57QCaMeta } from "/Users/rice/xtable-pos-v3/modules/product/pages/menu/openMenu.vue?macro=true";
import { default as menuLmUkPVipxyMeta } from "/Users/rice/xtable-pos-v3/modules/product/pages/menu.vue?macro=true";
import { default as index2LO3HX6feEMeta } from "/Users/rice/xtable-pos-v3/modules/reports/pages/index.vue?macro=true";
import { default as summary_45dailys8j0WHPrFJMeta } from "/Users/rice/xtable-pos-v3/modules/reports/pages/summary-daily.vue?macro=true";
import { default as receiptsO7nvbYy3IYMeta } from "/Users/rice/xtable-pos-v3/modules/reports/pages/receipts.vue?macro=true";
import { default as voided_45billsj6KzxSWHaNMeta } from "/Users/rice/xtable-pos-v3/modules/reports/pages/voided-bills.vue?macro=true";
import { default as productsqardcNzkEDMeta } from "/Users/rice/xtable-pos-v3/modules/reports/pages/products.vue?macro=true";
import { default as products_45dailyFH1oopQuFnMeta } from "/Users/rice/xtable-pos-v3/modules/reports/pages/products-daily.vue?macro=true";
import { default as transaction_45by_45houroU8qFspL51Meta } from "/Users/rice/xtable-pos-v3/modules/reports/pages/transaction-by-hour.vue?macro=true";
import { default as products_45voidedOCwGsk7U8lMeta } from "/Users/rice/xtable-pos-v3/modules/reports/pages/products-voided.vue?macro=true";
import { default as change_45products_45price8qZztCeVHfMeta } from "/Users/rice/xtable-pos-v3/modules/reports/pages/change-products-price.vue?macro=true";
import { default as products_45open_45menuQ67Ib1txO4Meta } from "/Users/rice/xtable-pos-v3/modules/reports/pages/products-open-menu.vue?macro=true";
import { default as activity_45logZIqLOFtVSzMeta } from "/Users/rice/xtable-pos-v3/modules/reports/pages/activity-log.vue?macro=true";
import { default as sales_45by_45employeeHzZSl7HiigMeta } from "/Users/rice/xtable-pos-v3/modules/reports/pages/sales-by-employee.vue?macro=true";
import { default as indexzih46HGLDtMeta } from "/Users/rice/xtable-pos-v3/modules/setting/pages/settings/index.vue?macro=true";
import { default as tablesZlbhtWYJQHMeta } from "/Users/rice/xtable-pos-v3/modules/setting/pages/settings/tables.vue?macro=true";
import { default as channelsSYWzDO3Ub7Meta } from "/Users/rice/xtable-pos-v3/modules/setting/pages/settings/channels.vue?macro=true";
import { default as employeesPSS1KCmhntMeta } from "/Users/rice/xtable-pos-v3/modules/setting/pages/settings/employees.vue?macro=true";
import { default as paymentswNUwrbB2OCMeta } from "/Users/rice/xtable-pos-v3/modules/setting/pages/settings/payments.vue?macro=true";
import { default as printersryBoYtXYlSMeta } from "/Users/rice/xtable-pos-v3/modules/setting/pages/settings/printers.vue?macro=true";
import { default as mobile_45connectJyYzliCk0OMeta } from "/Users/rice/xtable-pos-v3/modules/setting/pages/settings/mobile-connect.vue?macro=true";
import { default as invite_45userTCHajj7FlWMeta } from "/Users/rice/xtable-pos-v3/modules/setting/pages/settings/invite-user.vue?macro=true";
import { default as receipt_45tagsaAjINgBzNWMeta } from "/Users/rice/xtable-pos-v3/modules/setting/pages/settings/receipt-tags.vue?macro=true";
import { default as indexI3JENdEVTnMeta } from "/Users/rice/xtable-pos-v3/modules/setting/pages/index.vue?macro=true";
export default [
  {
    name: _404jbEN0VlOpWMeta?.name ?? "404",
    path: _404jbEN0VlOpWMeta?.path ?? "/404",
    meta: _404jbEN0VlOpWMeta || {},
    alias: _404jbEN0VlOpWMeta?.alias || [],
    redirect: _404jbEN0VlOpWMeta?.redirect,
    component: () => import("/Users/rice/xtable-pos-v3/pages/404.vue").then(m => m.default || m)
  },
  {
    name: expiredY98HGdYJWnMeta?.name ?? "expired",
    path: expiredY98HGdYJWnMeta?.path ?? "/expired",
    meta: expiredY98HGdYJWnMeta || {},
    alias: expiredY98HGdYJWnMeta?.alias || [],
    redirect: expiredY98HGdYJWnMeta?.redirect,
    component: () => import("/Users/rice/xtable-pos-v3/pages/expired.vue").then(m => m.default || m)
  },
  {
    name: forgot_45passwordnf3m2vyfLNMeta?.name ?? "forgot-password",
    path: forgot_45passwordnf3m2vyfLNMeta?.path ?? "/forgot-password",
    meta: forgot_45passwordnf3m2vyfLNMeta || {},
    alias: forgot_45passwordnf3m2vyfLNMeta?.alias || [],
    redirect: forgot_45passwordnf3m2vyfLNMeta?.redirect,
    component: () => import("/Users/rice/xtable-pos-v3/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: indexe9ScMyZqTnMeta?.name ?? "index",
    path: indexe9ScMyZqTnMeta?.path ?? "/",
    meta: indexe9ScMyZqTnMeta || {},
    alias: indexe9ScMyZqTnMeta?.alias || [],
    redirect: indexe9ScMyZqTnMeta?.redirect,
    component: () => import("/Users/rice/xtable-pos-v3/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginlfIMpUCoYFMeta?.name ?? "login",
    path: loginlfIMpUCoYFMeta?.path ?? "/login",
    meta: loginlfIMpUCoYFMeta || {},
    alias: loginlfIMpUCoYFMeta?.alias || [],
    redirect: loginlfIMpUCoYFMeta?.redirect,
    component: () => import("/Users/rice/xtable-pos-v3/pages/login.vue").then(m => m.default || m)
  },
  {
    name: notallowedbLnoPAwsF4Meta?.name ?? "notallowed",
    path: notallowedbLnoPAwsF4Meta?.path ?? "/notallowed",
    meta: notallowedbLnoPAwsF4Meta || {},
    alias: notallowedbLnoPAwsF4Meta?.alias || [],
    redirect: notallowedbLnoPAwsF4Meta?.redirect,
    component: () => import("/Users/rice/xtable-pos-v3/pages/notallowed.vue").then(m => m.default || m)
  },
  {
    name: runmode46stoh9igdMeta?.name ?? "runmode",
    path: runmode46stoh9igdMeta?.path ?? "/runmode",
    meta: runmode46stoh9igdMeta || {},
    alias: runmode46stoh9igdMeta?.alias || [],
    redirect: runmode46stoh9igdMeta?.redirect,
    component: () => import("/Users/rice/xtable-pos-v3/pages/runmode.vue").then(m => m.default || m)
  },
  {
    name: signupx92gHNJ9ImMeta?.name ?? "signup",
    path: signupx92gHNJ9ImMeta?.path ?? "/signup",
    meta: signupx92gHNJ9ImMeta || {},
    alias: signupx92gHNJ9ImMeta?.alias || [],
    redirect: signupx92gHNJ9ImMeta?.redirect,
    component: () => import("/Users/rice/xtable-pos-v3/pages/signup.vue").then(m => m.default || m)
  },
  {
    name: unauthorizedajQwWkGQ8HMeta?.name ?? "unauthorized",
    path: unauthorizedajQwWkGQ8HMeta?.path ?? "/unauthorized",
    meta: unauthorizedajQwWkGQ8HMeta || {},
    alias: unauthorizedajQwWkGQ8HMeta?.alias || [],
    redirect: unauthorizedajQwWkGQ8HMeta?.redirect,
    component: () => import("/Users/rice/xtable-pos-v3/pages/unauthorized.vue").then(m => m.default || m)
  },
  {
    name: dashboard0k9MxbpLWZMeta?.name ?? "dashboard",
    path: dashboard0k9MxbpLWZMeta?.path ?? "/dashboard",
    meta: dashboard0k9MxbpLWZMeta || {},
    alias: dashboard0k9MxbpLWZMeta?.alias || [],
    redirect: dashboard0k9MxbpLWZMeta?.redirect,
    component: () => import("/Users/rice/xtable-pos-v3/modules/dashboard/pages/dashboard.vue").then(m => m.default || m),
    children: [
  {
    name: indexTViQ4uuFo2Meta?.name ?? "",
    path: indexTViQ4uuFo2Meta?.path ?? "",
    meta: indexTViQ4uuFo2Meta || {},
    alias: indexTViQ4uuFo2Meta?.alias || [],
    redirect: indexTViQ4uuFo2Meta?.redirect,
    component: () => import("/Users/rice/xtable-pos-v3/modules/dashboard/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: receipts4VxNeErPREMeta?.name ?? "receipts",
    path: receipts4VxNeErPREMeta?.path ?? "receipts",
    meta: receipts4VxNeErPREMeta || {},
    alias: receipts4VxNeErPREMeta?.alias || [],
    redirect: receipts4VxNeErPREMeta?.redirect,
    component: () => import("/Users/rice/xtable-pos-v3/modules/dashboard/pages/dashboard/receipts.vue").then(m => m.default || m)
  },
  {
    name: livehxmfIV9xU5Meta?.name ?? "live",
    path: livehxmfIV9xU5Meta?.path ?? "live",
    meta: livehxmfIV9xU5Meta || {},
    alias: livehxmfIV9xU5Meta?.alias || [],
    redirect: livehxmfIV9xU5Meta?.redirect,
    component: () => import("/Users/rice/xtable-pos-v3/modules/dashboard/pages/dashboard/live.vue").then(m => m.default || m)
  },
  {
    name: productszM0XdXj76VMeta?.name ?? "products",
    path: productszM0XdXj76VMeta?.path ?? "products",
    meta: productszM0XdXj76VMeta || {},
    alias: productszM0XdXj76VMeta?.alias || [],
    redirect: productszM0XdXj76VMeta?.redirect,
    component: () => import("/Users/rice/xtable-pos-v3/modules/dashboard/pages/dashboard/products.vue").then(m => m.default || m)
  },
  {
    name: voided_45itemsQPgkBKKp3TMeta?.name ?? "voided-items",
    path: voided_45itemsQPgkBKKp3TMeta?.path ?? "voided-items",
    meta: voided_45itemsQPgkBKKp3TMeta || {},
    alias: voided_45itemsQPgkBKKp3TMeta?.alias || [],
    redirect: voided_45itemsQPgkBKKp3TMeta?.redirect,
    component: () => import("/Users/rice/xtable-pos-v3/modules/dashboard/pages/dashboard/voided-items.vue").then(m => m.default || m)
  },
  {
    name: closeHXj1twe5zbMeta?.name ?? "close",
    path: closeHXj1twe5zbMeta?.path ?? "/dashboard/close",
    meta: closeHXj1twe5zbMeta || {},
    alias: closeHXj1twe5zbMeta?.alias || [],
    redirect: closeHXj1twe5zbMeta?.redirect,
    component: () => import("/Users/rice/xtable-pos-v3/modules/dashboard/pages/dashboard/close.vue").then(m => m.default || m)
  }
]
  },
  {
    name: logsmRI4lYznnaMeta?.name ?? "history",
    path: logsmRI4lYznnaMeta?.path ?? "/logs",
    meta: logsmRI4lYznnaMeta || {},
    alias: logsmRI4lYznnaMeta?.alias || [],
    redirect: logsmRI4lYznnaMeta?.redirect,
    component: () => import("/Users/rice/xtable-pos-v3/modules/history/pages/logs.vue").then(m => m.default || m)
  },
  {
    name: inventorynUcJqKwD4KMeta?.name ?? "inventory",
    path: inventorynUcJqKwD4KMeta?.path ?? "/inventory",
    meta: inventorynUcJqKwD4KMeta || {},
    alias: inventorynUcJqKwD4KMeta?.alias || [],
    redirect: inventorynUcJqKwD4KMeta?.redirect,
    component: () => import("/Users/rice/xtable-pos-v3/modules/inventory/pages/inventory.vue").then(m => m.default || m),
    children: [
  {
    name: listpf9LorVIzJMeta?.name ?? "list",
    path: listpf9LorVIzJMeta?.path ?? "list",
    meta: listpf9LorVIzJMeta || {},
    alias: listpf9LorVIzJMeta?.alias || [],
    redirect: listpf9LorVIzJMeta?.redirect,
    component: () => import("/Users/rice/xtable-pos-v3/modules/inventory/pages/inventory/list.vue").then(m => m.default || m)
  },
  {
    name: insertKq2KjRtWi1Meta?.name ?? "insert",
    path: insertKq2KjRtWi1Meta?.path ?? "insert",
    meta: insertKq2KjRtWi1Meta || {},
    alias: insertKq2KjRtWi1Meta?.alias || [],
    redirect: insertKq2KjRtWi1Meta?.redirect,
    component: () => import("/Users/rice/xtable-pos-v3/modules/inventory/pages/inventory/insert.vue").then(m => m.default || m)
  },
  {
    name: add7XI8aK5OtRMeta?.name ?? "add",
    path: add7XI8aK5OtRMeta?.path ?? "add",
    meta: add7XI8aK5OtRMeta || {},
    alias: add7XI8aK5OtRMeta?.alias || [],
    redirect: add7XI8aK5OtRMeta?.redirect,
    component: () => import("/Users/rice/xtable-pos-v3/modules/inventory/pages/inventory/add.vue").then(m => m.default || m)
  },
  {
    name: editvGE8jqpEzjMeta?.name ?? "edit",
    path: editvGE8jqpEzjMeta?.path ?? "edit",
    meta: editvGE8jqpEzjMeta || {},
    alias: editvGE8jqpEzjMeta?.alias || [],
    redirect: editvGE8jqpEzjMeta?.redirect,
    component: () => import("/Users/rice/xtable-pos-v3/modules/inventory/pages/inventory/edit.vue").then(m => m.default || m)
  },
  {
    name: detailiC4trxDgEYMeta?.name ?? "detail",
    path: detailiC4trxDgEYMeta?.path ?? "detail",
    meta: detailiC4trxDgEYMeta || {},
    alias: detailiC4trxDgEYMeta?.alias || [],
    redirect: detailiC4trxDgEYMeta?.redirect,
    component: () => import("/Users/rice/xtable-pos-v3/modules/inventory/pages/inventory/detail.vue").then(m => m.default || m)
  },
  {
    name: reportJOXJ1VENtKMeta?.name ?? "inventory-report",
    path: reportJOXJ1VENtKMeta?.path ?? "report",
    meta: reportJOXJ1VENtKMeta || {},
    alias: reportJOXJ1VENtKMeta?.alias || [],
    redirect: reportJOXJ1VENtKMeta?.redirect,
    component: () => import("/Users/rice/xtable-pos-v3/modules/inventory/pages/inventory/report.vue").then(m => m.default || m)
  }
]
  },
  {
    name: index4ZoJ4nVQJSMeta?.name ?? "pos",
    path: index4ZoJ4nVQJSMeta?.path ?? "/pos",
    meta: index4ZoJ4nVQJSMeta || {},
    alias: index4ZoJ4nVQJSMeta?.alias || [],
    redirect: index4ZoJ4nVQJSMeta?.redirect,
    component: () => import("/Users/rice/xtable-pos-v3/modules/pos/pages/index.vue").then(m => m.default || m)
  },
  {
    name: cartokBaYDZQRdMeta?.name ?? "cart",
    path: cartokBaYDZQRdMeta?.path ?? "/pos/cart",
    meta: cartokBaYDZQRdMeta || {},
    alias: cartokBaYDZQRdMeta?.alias || [],
    redirect: cartokBaYDZQRdMeta?.redirect,
    component: () => import("/Users/rice/xtable-pos-v3/modules/pos/pages/cart.vue").then(m => m.default || m)
  },
  {
    name: menuLmUkPVipxyMeta?.name ?? "menu",
    path: menuLmUkPVipxyMeta?.path ?? "/menu",
    meta: menuLmUkPVipxyMeta || {},
    alias: menuLmUkPVipxyMeta?.alias || [],
    redirect: menuLmUkPVipxyMeta?.redirect,
    component: () => import("/Users/rice/xtable-pos-v3/modules/product/pages/menu.vue").then(m => m.default || m),
    children: [
  {
    name: indexHMSLLnNF0EMeta?.name ?? "",
    path: indexHMSLLnNF0EMeta?.path ?? "",
    meta: indexHMSLLnNF0EMeta || {},
    alias: indexHMSLLnNF0EMeta?.alias || [],
    redirect: indexHMSLLnNF0EMeta?.redirect,
    component: () => import("/Users/rice/xtable-pos-v3/modules/product/pages/menu/index.vue").then(m => m.default || m)
  },
  {
    name: groupsIFdcWRugD1Meta?.name ?? "groups",
    path: groupsIFdcWRugD1Meta?.path ?? "groups",
    meta: groupsIFdcWRugD1Meta || {},
    alias: groupsIFdcWRugD1Meta?.alias || [],
    redirect: groupsIFdcWRugD1Meta?.redirect,
    component: () => import("/Users/rice/xtable-pos-v3/modules/product/pages/menu/groups.vue").then(m => m.default || m)
  },
  {
    name: itemsIhApGYbW2aMeta?.name ?? "items",
    path: itemsIhApGYbW2aMeta?.path ?? "items",
    meta: itemsIhApGYbW2aMeta || {},
    alias: itemsIhApGYbW2aMeta?.alias || [],
    redirect: itemsIhApGYbW2aMeta?.redirect,
    component: () => import("/Users/rice/xtable-pos-v3/modules/product/pages/menu/items.vue").then(m => m.default || m)
  },
  {
    name: addonwLomnObQ0RMeta?.name ?? "addon",
    path: addonwLomnObQ0RMeta?.path ?? "addon",
    meta: addonwLomnObQ0RMeta || {},
    alias: addonwLomnObQ0RMeta?.alias || [],
    redirect: addonwLomnObQ0RMeta?.redirect,
    component: () => import("/Users/rice/xtable-pos-v3/modules/product/pages/menu/addon.vue").then(m => m.default || m)
  },
  {
    name: qrcodeaKREs0TtbGMeta?.name ?? "qrcode",
    path: qrcodeaKREs0TtbGMeta?.path ?? "qrcode",
    meta: qrcodeaKREs0TtbGMeta || {},
    alias: qrcodeaKREs0TtbGMeta?.alias || [],
    redirect: qrcodeaKREs0TtbGMeta?.redirect,
    component: () => import("/Users/rice/xtable-pos-v3/modules/product/pages/menu/qrcode.vue").then(m => m.default || m)
  },
  {
    name: tagRDOcDTjqLcMeta?.name ?? "tag",
    path: tagRDOcDTjqLcMeta?.path ?? "tag",
    meta: tagRDOcDTjqLcMeta || {},
    alias: tagRDOcDTjqLcMeta?.alias || [],
    redirect: tagRDOcDTjqLcMeta?.redirect,
    component: () => import("/Users/rice/xtable-pos-v3/modules/product/pages/menu/tag.vue").then(m => m.default || m)
  },
  {
    name: categories80zCodwa4gMeta?.name ?? "category",
    path: categories80zCodwa4gMeta?.path ?? "categories",
    meta: categories80zCodwa4gMeta || {},
    alias: categories80zCodwa4gMeta?.alias || [],
    redirect: categories80zCodwa4gMeta?.redirect,
    component: () => import("/Users/rice/xtable-pos-v3/modules/product/pages/menu/categories.vue").then(m => m.default || m)
  },
  {
    name: channelBitE4NfFfCMeta?.name ?? "channel",
    path: channelBitE4NfFfCMeta?.path ?? "channel",
    meta: channelBitE4NfFfCMeta || {},
    alias: channelBitE4NfFfCMeta?.alias || [],
    redirect: channelBitE4NfFfCMeta?.redirect,
    component: () => import("/Users/rice/xtable-pos-v3/modules/product/pages/menu/channel.vue").then(m => m.default || m)
  },
  {
    name: printerLBrDHAWSmGMeta?.name ?? "printer",
    path: printerLBrDHAWSmGMeta?.path ?? "printer",
    meta: printerLBrDHAWSmGMeta || {},
    alias: printerLBrDHAWSmGMeta?.alias || [],
    redirect: printerLBrDHAWSmGMeta?.redirect,
    component: () => import("/Users/rice/xtable-pos-v3/modules/product/pages/menu/printer.vue").then(m => m.default || m)
  },
  {
    name: items_batchInc1O5SMVKMeta?.name ?? "items_batch",
    path: items_batchInc1O5SMVKMeta?.path ?? "items_batch",
    meta: items_batchInc1O5SMVKMeta || {},
    alias: items_batchInc1O5SMVKMeta?.alias || [],
    redirect: items_batchInc1O5SMVKMeta?.redirect,
    component: () => import("/Users/rice/xtable-pos-v3/modules/product/pages/menu/items_batch.vue").then(m => m.default || m)
  },
  {
    name: items_batch2ObmptZFzRzMeta?.name ?? "items_batch2",
    path: items_batch2ObmptZFzRzMeta?.path ?? "items_batch2",
    meta: items_batch2ObmptZFzRzMeta || {},
    alias: items_batch2ObmptZFzRzMeta?.alias || [],
    redirect: items_batch2ObmptZFzRzMeta?.redirect,
    component: () => import("/Users/rice/xtable-pos-v3/modules/product/pages/menu/items_batch2.vue").then(m => m.default || m)
  },
  {
    name: openMenu8fXnp57QCaMeta?.name ?? "openMenu",
    path: openMenu8fXnp57QCaMeta?.path ?? "openMenu",
    meta: openMenu8fXnp57QCaMeta || {},
    alias: openMenu8fXnp57QCaMeta?.alias || [],
    redirect: openMenu8fXnp57QCaMeta?.redirect,
    component: () => import("/Users/rice/xtable-pos-v3/modules/product/pages/menu/openMenu.vue").then(m => m.default || m)
  }
]
  },
  {
    name: index2LO3HX6feEMeta?.name ?? "reports",
    path: index2LO3HX6feEMeta?.path ?? "/reports",
    meta: index2LO3HX6feEMeta || {},
    alias: index2LO3HX6feEMeta?.alias || [],
    redirect: index2LO3HX6feEMeta?.redirect,
    component: () => import("/Users/rice/xtable-pos-v3/modules/reports/pages/index.vue").then(m => m.default || m)
  },
  {
    name: summary_45dailys8j0WHPrFJMeta?.name ?? "summary-daily",
    path: summary_45dailys8j0WHPrFJMeta?.path ?? "/reports/summary-daily",
    meta: summary_45dailys8j0WHPrFJMeta || {},
    alias: summary_45dailys8j0WHPrFJMeta?.alias || [],
    redirect: summary_45dailys8j0WHPrFJMeta?.redirect,
    component: () => import("/Users/rice/xtable-pos-v3/modules/reports/pages/summary-daily.vue").then(m => m.default || m)
  },
  {
    name: receiptsO7nvbYy3IYMeta?.name ?? "report-receipts",
    path: receiptsO7nvbYy3IYMeta?.path ?? "/reports/receipts",
    meta: receiptsO7nvbYy3IYMeta || {},
    alias: receiptsO7nvbYy3IYMeta?.alias || [],
    redirect: receiptsO7nvbYy3IYMeta?.redirect,
    component: () => import("/Users/rice/xtable-pos-v3/modules/reports/pages/receipts.vue").then(m => m.default || m)
  },
  {
    name: voided_45billsj6KzxSWHaNMeta?.name ?? "report-voided-bills",
    path: voided_45billsj6KzxSWHaNMeta?.path ?? "/reports/voided-bills",
    meta: voided_45billsj6KzxSWHaNMeta || {},
    alias: voided_45billsj6KzxSWHaNMeta?.alias || [],
    redirect: voided_45billsj6KzxSWHaNMeta?.redirect,
    component: () => import("/Users/rice/xtable-pos-v3/modules/reports/pages/voided-bills.vue").then(m => m.default || m)
  },
  {
    name: productsqardcNzkEDMeta?.name ?? "report-products",
    path: productsqardcNzkEDMeta?.path ?? "/reports/products",
    meta: productsqardcNzkEDMeta || {},
    alias: productsqardcNzkEDMeta?.alias || [],
    redirect: productsqardcNzkEDMeta?.redirect,
    component: () => import("/Users/rice/xtable-pos-v3/modules/reports/pages/products.vue").then(m => m.default || m)
  },
  {
    name: products_45dailyFH1oopQuFnMeta?.name ?? "products-daily",
    path: products_45dailyFH1oopQuFnMeta?.path ?? "/reports/products-daily",
    meta: products_45dailyFH1oopQuFnMeta || {},
    alias: products_45dailyFH1oopQuFnMeta?.alias || [],
    redirect: products_45dailyFH1oopQuFnMeta?.redirect,
    component: () => import("/Users/rice/xtable-pos-v3/modules/reports/pages/products-daily.vue").then(m => m.default || m)
  },
  {
    name: transaction_45by_45houroU8qFspL51Meta?.name ?? "transaction-by-hour",
    path: transaction_45by_45houroU8qFspL51Meta?.path ?? "/reports/transaction-by-hour",
    meta: transaction_45by_45houroU8qFspL51Meta || {},
    alias: transaction_45by_45houroU8qFspL51Meta?.alias || [],
    redirect: transaction_45by_45houroU8qFspL51Meta?.redirect,
    component: () => import("/Users/rice/xtable-pos-v3/modules/reports/pages/transaction-by-hour.vue").then(m => m.default || m)
  },
  {
    name: products_45voidedOCwGsk7U8lMeta?.name ?? "products-voided",
    path: products_45voidedOCwGsk7U8lMeta?.path ?? "/reports/products-voided",
    meta: products_45voidedOCwGsk7U8lMeta || {},
    alias: products_45voidedOCwGsk7U8lMeta?.alias || [],
    redirect: products_45voidedOCwGsk7U8lMeta?.redirect,
    component: () => import("/Users/rice/xtable-pos-v3/modules/reports/pages/products-voided.vue").then(m => m.default || m)
  },
  {
    name: change_45products_45price8qZztCeVHfMeta?.name ?? "change-products-price",
    path: change_45products_45price8qZztCeVHfMeta?.path ?? "/reports/change-products-price",
    meta: change_45products_45price8qZztCeVHfMeta || {},
    alias: change_45products_45price8qZztCeVHfMeta?.alias || [],
    redirect: change_45products_45price8qZztCeVHfMeta?.redirect,
    component: () => import("/Users/rice/xtable-pos-v3/modules/reports/pages/change-products-price.vue").then(m => m.default || m)
  },
  {
    name: products_45open_45menuQ67Ib1txO4Meta?.name ?? "products-open-menu",
    path: products_45open_45menuQ67Ib1txO4Meta?.path ?? "/reports/products-open-menu",
    meta: products_45open_45menuQ67Ib1txO4Meta || {},
    alias: products_45open_45menuQ67Ib1txO4Meta?.alias || [],
    redirect: products_45open_45menuQ67Ib1txO4Meta?.redirect,
    component: () => import("/Users/rice/xtable-pos-v3/modules/reports/pages/products-open-menu.vue").then(m => m.default || m)
  },
  {
    name: activity_45logZIqLOFtVSzMeta?.name ?? "activity-log",
    path: activity_45logZIqLOFtVSzMeta?.path ?? "/reports/activity-log",
    meta: activity_45logZIqLOFtVSzMeta || {},
    alias: activity_45logZIqLOFtVSzMeta?.alias || [],
    redirect: activity_45logZIqLOFtVSzMeta?.redirect,
    component: () => import("/Users/rice/xtable-pos-v3/modules/reports/pages/activity-log.vue").then(m => m.default || m)
  },
  {
    name: sales_45by_45employeeHzZSl7HiigMeta?.name ?? "sales-by-employee",
    path: sales_45by_45employeeHzZSl7HiigMeta?.path ?? "/reports/sales-by-employee",
    meta: sales_45by_45employeeHzZSl7HiigMeta || {},
    alias: sales_45by_45employeeHzZSl7HiigMeta?.alias || [],
    redirect: sales_45by_45employeeHzZSl7HiigMeta?.redirect,
    component: () => import("/Users/rice/xtable-pos-v3/modules/reports/pages/sales-by-employee.vue").then(m => m.default || m)
  },
  {
    name: indexI3JENdEVTnMeta?.name ?? "setting",
    path: indexI3JENdEVTnMeta?.path ?? "/settings",
    meta: indexI3JENdEVTnMeta || {},
    alias: indexI3JENdEVTnMeta?.alias || [],
    redirect: indexI3JENdEVTnMeta?.redirect,
    component: () => import("/Users/rice/xtable-pos-v3/modules/setting/pages/index.vue").then(m => m.default || m),
    children: [
  {
    name: indexzih46HGLDtMeta?.name ?? "",
    path: indexzih46HGLDtMeta?.path ?? "",
    meta: indexzih46HGLDtMeta || {},
    alias: indexzih46HGLDtMeta?.alias || [],
    redirect: indexzih46HGLDtMeta?.redirect,
    component: () => import("/Users/rice/xtable-pos-v3/modules/setting/pages/settings/index.vue").then(m => m.default || m)
  },
  {
    name: tablesZlbhtWYJQHMeta?.name ?? "tables",
    path: tablesZlbhtWYJQHMeta?.path ?? "tables",
    meta: tablesZlbhtWYJQHMeta || {},
    alias: tablesZlbhtWYJQHMeta?.alias || [],
    redirect: tablesZlbhtWYJQHMeta?.redirect,
    component: () => import("/Users/rice/xtable-pos-v3/modules/setting/pages/settings/tables.vue").then(m => m.default || m)
  },
  {
    name: channelsSYWzDO3Ub7Meta?.name ?? "channels",
    path: channelsSYWzDO3Ub7Meta?.path ?? "channels",
    meta: channelsSYWzDO3Ub7Meta || {},
    alias: channelsSYWzDO3Ub7Meta?.alias || [],
    redirect: channelsSYWzDO3Ub7Meta?.redirect,
    component: () => import("/Users/rice/xtable-pos-v3/modules/setting/pages/settings/channels.vue").then(m => m.default || m)
  },
  {
    name: employeesPSS1KCmhntMeta?.name ?? "employees",
    path: employeesPSS1KCmhntMeta?.path ?? "employees",
    meta: employeesPSS1KCmhntMeta || {},
    alias: employeesPSS1KCmhntMeta?.alias || [],
    redirect: employeesPSS1KCmhntMeta?.redirect,
    component: () => import("/Users/rice/xtable-pos-v3/modules/setting/pages/settings/employees.vue").then(m => m.default || m)
  },
  {
    name: paymentswNUwrbB2OCMeta?.name ?? "payments",
    path: paymentswNUwrbB2OCMeta?.path ?? "payments",
    meta: paymentswNUwrbB2OCMeta || {},
    alias: paymentswNUwrbB2OCMeta?.alias || [],
    redirect: paymentswNUwrbB2OCMeta?.redirect,
    component: () => import("/Users/rice/xtable-pos-v3/modules/setting/pages/settings/payments.vue").then(m => m.default || m)
  },
  {
    name: printersryBoYtXYlSMeta?.name ?? "printers",
    path: printersryBoYtXYlSMeta?.path ?? "printers",
    meta: printersryBoYtXYlSMeta || {},
    alias: printersryBoYtXYlSMeta?.alias || [],
    redirect: printersryBoYtXYlSMeta?.redirect,
    component: () => import("/Users/rice/xtable-pos-v3/modules/setting/pages/settings/printers.vue").then(m => m.default || m)
  },
  {
    name: mobile_45connectJyYzliCk0OMeta?.name ?? "mobile-connect",
    path: mobile_45connectJyYzliCk0OMeta?.path ?? "mobile-connect",
    meta: mobile_45connectJyYzliCk0OMeta || {},
    alias: mobile_45connectJyYzliCk0OMeta?.alias || [],
    redirect: mobile_45connectJyYzliCk0OMeta?.redirect,
    component: () => import("/Users/rice/xtable-pos-v3/modules/setting/pages/settings/mobile-connect.vue").then(m => m.default || m)
  },
  {
    name: invite_45userTCHajj7FlWMeta?.name ?? "invite-user",
    path: invite_45userTCHajj7FlWMeta?.path ?? "invite-user",
    meta: invite_45userTCHajj7FlWMeta || {},
    alias: invite_45userTCHajj7FlWMeta?.alias || [],
    redirect: invite_45userTCHajj7FlWMeta?.redirect,
    component: () => import("/Users/rice/xtable-pos-v3/modules/setting/pages/settings/invite-user.vue").then(m => m.default || m)
  },
  {
    name: receipt_45tagsaAjINgBzNWMeta?.name ?? "receipt-tags",
    path: receipt_45tagsaAjINgBzNWMeta?.path ?? "receipt-tags",
    meta: receipt_45tagsaAjINgBzNWMeta || {},
    alias: receipt_45tagsaAjINgBzNWMeta?.alias || [],
    redirect: receipt_45tagsaAjINgBzNWMeta?.redirect,
    component: () => import("/Users/rice/xtable-pos-v3/modules/setting/pages/settings/receipt-tags.vue").then(m => m.default || m)
  }
]
  }
]