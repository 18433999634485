import validate from "/Users/rice/xtable-pos-v3/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45global from "/Users/rice/xtable-pos-v3/middleware/auth.global.ts";
import expired_45global from "/Users/rice/xtable-pos-v3/middleware/expired.global.ts";
import manifest_45route_45rule from "/Users/rice/xtable-pos-v3/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45global,
  expired_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}